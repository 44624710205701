<ng-progress
  id="router-progressbar"
  [direction]="'ltr+'"
  [min]="20"
  [max]="1"
  [speed]="200"
  [trickleSpeed]="300"
  [spinner]="false"
  [spinnerPosition]="'left'"
  [thick]="true"
  [color]="'#20BCDC'"
  [ease]="'linear'"
  [fixed]="true"
/>

<router-outlet />
